import { PdfType } from "../../../../utils/Enums"
import UploadPdfSection from "../../components/UploadPdfSection"

interface PdfSectionProps {
    pdfConfigurations: Array<{
        file: File | undefined,
        type: PdfType,
        onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
        onFileInputDelete: () => void,
    }>
}

export const PdfSection: React.FC<PdfSectionProps> = ({
    pdfConfigurations,
}) => {
    return (
        <div className="space-y-[25px]">
            <div className="space-y-[15px]">
                {pdfConfigurations.map((config, index) => (
                    <UploadPdfSection
                        key={index}
                        uploadedFile={config.file}
                        pdfType={config.type}
                        onFileInputChange={config.onFileInputChange}
                        onFileInputDelete={config.onFileInputDelete}
                    />
                ))}
            </div>
        </div>
    )
}