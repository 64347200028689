import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import WhiteButton from "../../components/buttons/WhiteButton"
import LoadingSpinner from "../../components/Loader"
import { getPatientsApi } from "../../utils/apis/medical-user-service"
import { IMAGE_LOGO } from "../../utils/Constants"
import { useAuth } from "../../utils/context/AuthContext"
import { usePatients } from "../../utils/context/PatientsContext"

const FetchPatients = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const role = auth.role
    const { setPatients } = usePatients()

    const [errorFetchingUserData, setErrorFetchingUserData] = useState(false)

    const getPatients = () => {
        if (!idToken || !userId || !role) return
        getPatientsApi(
            idToken,
            userId,
            role,
            (patients) => {
                console.log("Patients fetched correctly")
                setPatients(patients)
                navigate("/fetch-appointments")
            },
            (error) => {
                console.log(error)
            }
        )
    }


    useEffect(() => {
        getPatients()
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center px-[20px] bg-white">
                    <div className="h-[35px] mb-[40px]">
                        <img src={IMAGE_LOGO} alt="Holifya Logo" />
                    </div>
                    {
                        errorFetchingUserData ?
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ❌ Errore
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Qualcosa è andato storto, ti chiediamo ti riprovare
                                </div>
                                <div className="w-[300px] h-[50px]">
                                    <WhiteButton
                                        text="Torna alla login page"
                                        onClick={() => navigate("/")}
                                    />
                                </div>
                            </div> :
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ⏳ Attendi...
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Stiamo caricando i tuoi pazienti.
                                </div>
                                <div><LoadingSpinner /></div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FetchPatients
