import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";

import BackButton from "../../../components/buttons/new/BackButton";
import { Patient } from "../../../utils/api-objects/Patient";
import { getAllExamsByType } from "../../../utils/apis/emr-service";
import { BucketFolder, PdfType } from "../../../utils/Enums";
import PdfCard from "../components/PdfCard";
import { PdfExam } from "../components/PdfExam";

const BloodTestPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const [dnaTestPdf, setDnaTestPdf] = useState<PdfExam>()

    const navigate = useNavigate()

    useEffect(() => {
        if (!patients) {
            setIsLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);

        if (!patientId || !idToken) return

        getAllExamsByType(
            patientId,
            idToken,
            BucketFolder.BloodExams,
            (exams) => {
                const exam = exams[0]
                setDnaTestPdf(exam)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            },
        )
    }, [patients, patientId])

    if (isLoading) {
        return (
            <div className="w-full h-full pt-[20px] flex flex-col items-center ">
                <div>
                    Attendi un attimo... <br />
                    Stiamo caricando i dati del paziente
                </div>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <BackButton
                text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
            />
            <div className="my-[20px]">
                <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                <div className="font-bold text-[35px] leading-[35px]">Analisi degli Aminoacidi</div>
            </div>
            {
                dnaTestPdf ?
                    <PdfCard
                        exam={dnaTestPdf}
                        pdfType={PdfType.BloodTest} /> :
                    <div>Pdf non trovato, esci e rientra</div>
            }
        </div>
    )
}

export default BloodTestPage
