interface Props {
    text: string,
    onClick?: () => void,
    pointer: boolean,
    disabled: boolean,
    fontSize?: number,
    isLoading?: boolean,
    uppercase?: boolean,
  }
  
  const PrimaryButton: React.FC<Props> = ({
    text,
    onClick,
    pointer,
    disabled,
    fontSize = 14,
    isLoading = false,
    uppercase = true,
  }) => {
    return (
      <button
        className={`w-full h-full rounded-[5px] flex items-center justify-center ${uppercase && "uppercase"} font-bold text-white bg-primary-action hover:bg-primary-action-hover active:bg-primary-action-active disabled:bg-primary-action-disabled ${pointer ? "cursor-pointer" : "cursor-not-allowed"} text-[${fontSize}px]`}
        onClick={onClick}
        disabled={disabled}
      >
        {
          isLoading ? (<div className="border-4 border-solid border-white border-t-blue-500 rounded-full w-5 h-5 animate-spin" />) : text
        }
      </button>
    )
  }
  
  export default PrimaryButton
  