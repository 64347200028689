import { useAppointments } from "../../utils/context/AppointmentsContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { handleMissingDays } from "../../utils/Functions"
import { NewAppointmentWidget } from "./components/NewAppointmentWidget"

const Home = () => {
  const { userData } = useUserData()
  const username = userData.name
  const email = userData.email
  const { appointmentsState } = useAppointments()
  const allFutureActiveAppointments = appointmentsState.allFutureActiveAppointments
  const allMyFutureAppointments = allFutureActiveAppointments?.filter(app => app.expert_email === email)
  const closestAppointment = allMyFutureAppointments && allMyFutureAppointments[0]

  // ********************************************************************************

  return (
    <div className='w-full h-full flex flex-col pl-[70px]'>
      <div className="font-bold text-[35px] leading-[55px] my-[30px]">Ciao {username}!</div>
      <div className="w-full flex-1">
        {closestAppointment ?
          <div className="w-[89%] h-full">
            <div className="font-bold text-[20px] leading-[33px] mb-[20px]">
              In programma {handleMissingDays(closestAppointment)}
            </div>
            <NewAppointmentWidget
              appointment={closestAppointment}
            />
          </div> :
          <div className="font-normal text-[16px] leading-[26px]">
            Qui potrai visualizzare tutti gli aggiornamenti recenti dei tuoi clienti
          </div>
        }
      </div>
    </div>
  )
}

export default Home
