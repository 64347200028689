/**
 * This component is the loading spinner with the shadow screen
 */
export const LoadingSpinner = () => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="flex items-center justify-center">
        <div className="w-16 h-16 border-4 border-solid border-blue-500 border-t-transparent rounded-full animate-spin"></div>
      </div>
    </div>
  )
}
