import { SortingItem } from "../api-objects/SortingItem"
import { BASE_URL, requestOptionsGET } from "../Constants"
import { ErrorWithMessageCallback, GetSortingItemsSuccessCallback } from "./callback-helpers"

export async function getAllSortingItemsApi(
    idToken: string,
    onSuccess: GetSortingItemsSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/sorting/all`, requestOptionsGET(idToken))
        if (response.ok) {
            const items: SortingItem[] = await response.json()
            onSuccess(items)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get sorting items failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}