import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import WhiteButton from "../../components/buttons/WhiteButton"
import Loader from "../../components/Loader"
import { getMedicalUserInfoApi } from "../../utils/apis/medical-user-service"
import { IMAGE_LOGO } from "../../utils/Constants"
import { useAuth } from "../../utils/context/AuthContext"
import { useUserData } from "../../utils/context/UserDataContext"

const FetchUserData = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { updateUserData, updateProfessionalUserData } = useUserData()

    const [errorFetchingUserData, setErrorFetchingUserData] = useState(false)

    const getMedicalUserInfo = () => {
        if (!userId || !idToken) return
        getMedicalUserInfoApi(
            idToken,
            userId,
            (user) => {
                const username = user.data.name
                const familyName = user.data.family_name
                const email = user.email
                const phoneNumber = user.data.phone_number
                const sex = user.data.sex
                updateUserData(
                    username,
                    familyName,
                    email,
                    phoneNumber,
                    sex
                )
                const bio = user.data.bio
                const professional_order = user.data.professional_order
                const professional_order_number = user.data.professional_order_number
                const professional_order_state = user.data.professional_order_state
                const tags = user.tags
                updateProfessionalUserData(
                    bio,
                    professional_order,
                    professional_order_number,
                    professional_order_state,
                    tags,
                )
                if (user.calendly_data.calendly_invitation_status !== "pending") {
                    navigate("/fetch-patients")
                } else {
                    navigate("/wall-page")
                }
            },
            (error) => {
                setErrorFetchingUserData(true)
            },
        )
    }

    useEffect(() => {
        getMedicalUserInfo()
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center px-[20px] bg-white">
                    <div className="h-[35px] mb-[40px]">
                        <img src={IMAGE_LOGO} alt="Holifya Logo" />
                    </div>
                    {
                        errorFetchingUserData ?
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ❌ Errore
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Qualcosa è andato storto, ti chiediamo ti riprovare
                                </div>
                                <div className="w-[300px] h-[50px]">
                                    <WhiteButton
                                        text="Torna alla login page"
                                        onClick={() => navigate("/")}
                                    />
                                </div>
                            </div> :
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ⏳ Attendi...
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Stiamo caricando i tuoi dati.
                                </div>
                                <div><Loader /></div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FetchUserData
