import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { BASE_URL, DEFAULT_SKILLS } from "../../utils/Constants"
import { CompleteAccountPageState } from "../../utils/Enums"
import SkillCard from "./components/SkillCard"
import "./css/EmailRegistrationCompleteAccount.css"
const EmailRegistrationCompleteAccount = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const name = location.state.name
    const familyName = location.state.familyName
    const idToken = location.state.idToken
    const accessToken = location.state.accessToken
    const userId = location.state.userId
    const refreshToken = location.state.refreshToken

    const [titolo, setTitolo] = useState("")
    const [professionalOrder, setProfessionalOrder] = useState("")
    const [professionalOrderNumber, setProfessionalOrderNumber] = useState("")
    const [professionalOrderState, setProfessionalOrderState] = useState("")
    const [bio, setBio] = useState("")

    const [newSkill, setNewSkill] = useState("")

    const onConfirmClick = () => {
        callUpdateMedicalUserApi()
    }

    const [skillsSelected, setSkillsSelected] = useState<string[]>([])

    const onSkillCardClick = (skill: string) => {
        handleSelectedSkillsArray(skill)
    }

    const handleSelectedSkillsArray = (skill: string) => {
        const index = skillsSelected.indexOf(skill);
        if (index > -1) {
            setSkillsSelected([...skillsSelected.slice(0, index), ...skillsSelected.slice(index + 1)]);
        } else {
            setSkillsSelected([...skillsSelected, ...[skill]]);
        }
    }

    const onAddNewSkillClicked = () => {
        switch (pageState) {
            case CompleteAccountPageState.NoEdit:
                setPageState(CompleteAccountPageState.Edit)
                break;
            case CompleteAccountPageState.Edit: {
                if (newSkill !== "") {
                    handleSelectedSkillsArray(newSkill)
                    const newSkills = skills
                    newSkills.push(newSkill)
                    setSkills(newSkills)
                    setNewSkill("")
                }
                setPageState(CompleteAccountPageState.NoEdit)
                break;
            }
        }
    }
    const [isLoading, setIsLoading] = useState(false)

    async function callUpdateMedicalUserApi() {
        setIsLoading(true)
        const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken!,
            },
            body: JSON.stringify(
                {
                    data: {
                        name: name,
                        family_name: familyName,
                        sex: titolo === "Dott." ? "M" : "F",
                        professional_order: professionalOrder,
                        professional_order_number: professionalOrderNumber,
                        professional_order_state: professionalOrderState,
                        bio: bio,
                    },
                    tags: skillsSelected,
                })
        })
        setIsLoading(false)
        if (responseUserinfo.ok) {
            const responseInfoJson = await responseUserinfo.json()
            navigate("/wall-page", { state: { name, idToken, userId, accessToken, refreshToken } })
        } else {
            const responseInfoJson = await responseUserinfo.json()
            console.log(responseInfoJson)
        }
    }

    const [pageState, setPageState] = useState(CompleteAccountPageState.NoEdit)

    const confirmButtonDisabled = titolo === "" || professionalOrder === "" || professionalOrderNumber === "" || professionalOrderState === "" || bio === "" || skillsSelected.length === 0

    const [skills, setSkills] = useState(DEFAULT_SKILLS)

    return (
        <div className="App">
            <div className="email-registration-complete-account-container">
                <div className="email-registration-complete-account-internal-container">
                    <div className="h-[35px]" />
                    <div>
                        <img src="/images/holifyaLogos/holifya-logo-blue.svg" />
                    </div>
                    <div className="h-[35px]" />
                    <div className="email-registration-complete-account-heading">
                        Ciao {name}! {String.fromCodePoint(0x1F642)} Completa il tuo account
                    </div>
                    <div className="h-[20px]" />
                    <div className="email-registration-complete-account-multiple-inputs-container">
                        <div className="email-registration-complete-account-input-container">
                            <label className="email-registration-complete-account-input-label">
                                Titolo
                            </label>
                            <div className="email-registration-complete-account-input-internal-container">
                                <input
                                    className="email-registration-complete-account-input-field s16 w400"
                                    type="text"
                                    placeholder="Dott. / Dott.ssa"
                                    onChange={
                                        (e) => setTitolo(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-complete-account-input-container">
                            <label className="email-registration-complete-account-input-label">
                                Iscritto all'ordine
                            </label>
                            <div className="email-registration-complete-account-input-internal-container">
                                <input
                                    className="email-registration-complete-account-input-field s16 w400"
                                    type="text"
                                    placeholder="Inserisci l'ordine"
                                    onChange={
                                        (e) => setProfessionalOrder(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-complete-account-input-container">
                            <label className="email-registration-complete-account-input-label">
                                Numero di iscrizione all'ordine
                            </label>
                            <div className="email-registration-complete-account-input-internal-container">
                                <input
                                    className="email-registration-complete-account-input-field s16 w400"
                                    type="text"
                                    placeholder="Numero di iscrizione all'Ordine"
                                    onChange={
                                        (e) => setProfessionalOrderNumber(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                        <div className="email-registration-complete-account-input-container">
                            <label className="email-registration-complete-account-input-label">
                                Ordine di
                            </label>
                            <div className="email-registration-complete-account-input-internal-container">
                                <input
                                    className="email-registration-complete-account-input-field s16 w400"
                                    type="text"
                                    placeholder="Provincia dell'ordine"
                                    onChange={
                                        (e) => setProfessionalOrderState(e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="h-[20px]" />
                    <div className="email-registration-complete-account-skills-area">
                        <div className="email-registration-complete-account-skills-area-heading">
                            Aree di competenza
                        </div>
                        <div className="h-[20px]" />
                        <div className="email-registration-complete-account-skills-container">
                            {
                                skills.map(skill => (
                                    <SkillCard
                                        key={skills.indexOf(skill)}
                                        skill={skill}
                                        onClick={onSkillCardClick}
                                        isChecked={skillsSelected.includes(skill)}
                                    />
                                ))
                            }
                        </div>
                        <div className="h-[20px]" />
                        {
                            pageState === "edit" ?
                                <div className="email-registration-complete-account-input-container">
                                    <label className="email-registration-complete-account-input-label">
                                        Specializzazione
                                    </label>
                                    <div className="email-registration-complete-account-input-internal-container">
                                        <input
                                            className="email-registration-complete-account-input-field s16 w400"
                                            type="text"
                                            placeholder="Inserisci nuova specializzazione"
                                            onChange={
                                                (e) => setNewSkill(e.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="h-[20px]" />
                                </div> : null
                        }
                        <button
                            className={`email-registration-complete-account-add-new-skill-button ${pageState === CompleteAccountPageState.NoEdit ? "ternary-action-color-background" : "primary-action-color-background"} font-bold text-[14px] uppercase`}
                            onClick={onAddNewSkillClicked}
                        >
                            Aggiungi nuova
                        </button>
                        <div className="h-[20px]" />
                    </div>
                    <div className="h-[20px]" />
                    <div className="email-registration-complete-account-bio-area">
                        <div className="email-registration-complete-account-bio-heading">
                            Bio
                        </div>
                        <div className="h-[10px]" />
                        <textarea
                            className="email-registration-complete-account-text-area"
                            onChange={(e) => setBio(e.target.value)}
                            placeholder="Racconta che professionista sei in massimo 700 caratteri"
                        />
                        <div className="email-registration-complete-account-counter">{bio.split(" ").join("").length} su 700</div>
                    </div>
                    <div className="h-[20px]" />
                    <button
                        className={`email-registration-complete-account-confirm-button primary-action-color-background ${confirmButtonDisabled ? "" : "cursor-pointer"}`}
                        onClick={onConfirmClick}
                        disabled={confirmButtonDisabled}
                    >
                        Conferma
                    </button>
                    <div className="h-[20px]" />
                </div>
            </div>
        </div>
    )
}

export default EmailRegistrationCompleteAccount
