import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Appointment } from "../../pages/platform/components/Appointment";

interface AppointmentsState {
    allAppointments: Appointment[] | null;
    allActiveAppointments: Appointment[] | null;
    allFutureActiveAppointments: Appointment[] | null;
    allOldActiveAppointments: Appointment[] | null;
}

interface AppointmentsContextType {
    appointmentsState: AppointmentsState;
    setAppointments: (appointments: Appointment[]) => void;
    setActiveAppointments: (activeAppointments: Appointment[]) => void;
    setFutureActiveAppointments: (futureActiveAppointments: Appointment[]) => void;
    setOldActiveAppointments: (oldActiveAppointments: Appointment[]) => void;
    clearAppointments: () => void;
}

const AppointmentsContext = createContext<AppointmentsContextType | undefined>(undefined);

export const AppointmentsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [appointmentsState, setAppointmentsState] = useState<AppointmentsState>(() => {
        try {
            const storedAppointments = localStorage.getItem("allAppointments");
            const storedActiveAppointments = localStorage.getItem("allActiveAppointments");
            const storedFutureActiveAppointments = localStorage.getItem("allFutureActiveAppointments");
            const storedOldActiveAppointments = localStorage.getItem("allOldActiveAppointments");
            return {
                allAppointments: storedAppointments ? JSON.parse(storedAppointments) : null,
                allActiveAppointments: storedActiveAppointments ? JSON.parse(storedActiveAppointments) : null,
                allFutureActiveAppointments: storedFutureActiveAppointments ? JSON.parse(storedFutureActiveAppointments) : null,
                allOldActiveAppointments: storedOldActiveAppointments ? JSON.parse(storedOldActiveAppointments) : null
            };
        } catch (error) {
            console.error("Failed to parse appointments from localStorage:", error);
            return { allAppointments: null, allActiveAppointments: null, allFutureActiveAppointments: null, allOldActiveAppointments: null };
        }
    });

    useEffect(() => {
        if (appointmentsState.allAppointments) {
            localStorage.setItem("allAppointments", JSON.stringify(appointmentsState.allAppointments));
        } else {
            localStorage.removeItem("allAppointments");
        }

        if (appointmentsState.allActiveAppointments) {
            localStorage.setItem("allActiveAppointments", JSON.stringify(appointmentsState.allActiveAppointments));
        } else {
            localStorage.removeItem("allActiveAppointments");
        }

        if (appointmentsState.allFutureActiveAppointments) {
            localStorage.setItem("allFutureActiveAppointments", JSON.stringify(appointmentsState.allFutureActiveAppointments));
        } else {
            localStorage.removeItem("allFutureActiveAppointments");
        }

        if (appointmentsState.allOldActiveAppointments) {
            localStorage.setItem("allOldActiveAppointments", JSON.stringify(appointmentsState.allOldActiveAppointments));
        } else {
            localStorage.removeItem("allOldActiveAppointments");
        }
    }, [appointmentsState.allAppointments, appointmentsState.allActiveAppointments, appointmentsState.allFutureActiveAppointments, appointmentsState.allOldActiveAppointments]);

    const setAppointments = (allAppointments: Appointment[] | null) => {
        setAppointmentsState((prev) => ({ ...prev, allAppointments }));
    };

    const setActiveAppointments = (allActiveAppointments: Appointment[] | null) => {
        setAppointmentsState((prev) => ({ ...prev, allActiveAppointments }));
    };

    const setFutureActiveAppointments = (allFutureActiveAppointments: Appointment[] | null) => {
        setAppointmentsState((prev) => ({ ...prev, allFutureActiveAppointments }));
    };

    const setOldActiveAppointments = (allOldActiveAppointments: Appointment[] | null) => {
        setAppointmentsState((prev) => ({ ...prev, allOldActiveAppointments }));
    };

    const clearAppointments = () => {
        setAppointmentsState({ allAppointments: null, allActiveAppointments: null, allFutureActiveAppointments: null, allOldActiveAppointments: null });
        localStorage.removeItem("allAppointments");
        localStorage.removeItem("allActiveAppointments");
        localStorage.removeItem("allFutureActiveAppointments");
        localStorage.removeItem("allOldActiveAppointments");
    };

    return (
        <AppointmentsContext.Provider value={{ appointmentsState, setAppointments, setActiveAppointments, setFutureActiveAppointments, setOldActiveAppointments, clearAppointments }}>
            {children}
        </AppointmentsContext.Provider>
    );
};

export const useAppointments = (): AppointmentsContextType => {
    const context = useContext(AppointmentsContext);
    if (context === undefined) {
        throw new Error('useAppointments must be used within an AppointmentsProvider');
    }
    return context;
};