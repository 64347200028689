import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { SurveyCompleted } from "../../../utils/api-objects/SurveyCompleted"
import { getAllSortingItemsApi } from "../../../utils/apis/order-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { addOneHour } from "../../../utils/Functions"

const Analytics = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [purchasesLength, setPurchasesLength] = useState<number>()
    const [purchasesThisMonthLength, setPurchasesThisMonthLength] = useState<number>()
    const [purchasesPreviusMonthLength, setPurchasesPreviousMonthLength] = useState<number>()
    const [purchasesThisWeekLength, setPurchasesThisWeekLength] = useState<number>()
    const [purchasesPreviousWeekLength, setPurchasesPreviousWeekLength] = useState<number>()

    const [surveys, setSurveys] = useState<SurveyCompleted[]>()

    useEffect(() => {
        if (!idToken) return
        setIsLoading(true)
        getAllSortingItemsApi(
            idToken,
            (items) => {
                const currentDate = new Date();
                const currentMonth = currentDate.getMonth();
                const currentYear = currentDate.getFullYear();
                const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
                const previousMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
                // Start and End of Current Week (Monday-Sunday)
                const currentWeekStart = new Date(currentDate);
                currentWeekStart.setDate(currentDate.getDate() - currentDate.getDay() + 1); // Monday
                currentWeekStart.setHours(0, 0, 0, 0);
                const currentWeekEnd = new Date(currentWeekStart);
                currentWeekEnd.setDate(currentWeekStart.getDate() + 6); // Sunday
                currentWeekEnd.setHours(23, 59, 59, 999);

                // Start and End of Previous Week (Monday-Sunday)
                const previousWeekStart = new Date(currentWeekStart);
                previousWeekStart.setDate(currentWeekStart.getDate() - 7);
                const previousWeekEnd = new Date(currentWeekEnd);
                previousWeekEnd.setDate(currentWeekEnd.getDate() - 7);

                const createdThisMonth = items.filter(item => {
                    const itemDate = addOneHour(new Date(item.created_at));
                    return (
                        itemDate.getMonth() === currentMonth &&
                        itemDate.getFullYear() === currentYear
                    );
                });

                const createdLastMonth = items.filter(item => {
                    const itemDate = addOneHour(new Date(item.created_at));
                    return (
                        itemDate.getMonth() === previousMonth &&
                        itemDate.getFullYear() === previousMonthYear
                    );
                });

                const createdThisWeek = items.filter(item => {
                    const itemDate = addOneHour(new Date(item.created_at));
                    return itemDate >= currentWeekStart && itemDate <= currentWeekEnd;
                });

                const createdLastWeek = items.filter(item => {
                    const itemDate = addOneHour(new Date(item.created_at));
                    return itemDate >= previousWeekStart && itemDate <= previousWeekEnd;
                });

                setPurchasesLength(items.length)
                setPurchasesThisMonthLength(createdThisMonth.length)
                setPurchasesPreviousMonthLength(createdLastMonth.length)
                setPurchasesThisWeekLength(createdThisWeek.length);
                setPurchasesPreviousWeekLength(createdLastWeek.length);
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            },
        )
    }, [])

    // ********************************************************************************

    if (isLoading) {
        return (
            <LoadingSpinner />
        );
    }

    return (
        <div className="w-full h-full flex flex-col pl-[70px]">
            <div className="font-bold text-[35px] leading-[55px] my-[30px]">
                Analytics
            </div>
            <div className="w-[89%] flex flex-col flex-1">
                <div className="grid grid-cols-2 gap-4">
                    <AnalyticsBox onClick={() => navigate("purchases")}>
                        <div className="space-y-[20px]">
                            <div className="font-bold text-[14px] leading-[20px]">Acquisti su shopify</div>
                            <div className="grid grid-cols-3 gap-1 font-medium text-[10px] leading-[17px]">
                                <div>Totali: {purchasesLength}</div>
                                <div className="space-y-[5px]">
                                    <div>Questo mese: {purchasesThisMonthLength}</div>
                                    <div>Mese precedente: {purchasesPreviusMonthLength}</div>
                                    <div>MoM value: {(((purchasesThisMonthLength! - purchasesPreviusMonthLength!) / purchasesPreviusMonthLength!) * 100).toFixed(2)}%</div>
                                </div>
                                <div className="space-y-[5px]">
                                    <div>Questa settimana: {purchasesThisWeekLength}</div>
                                    <div>Settimana precedente: {purchasesPreviousWeekLength}</div>
                                    <div>Wow value: {(((purchasesThisWeekLength! - purchasesPreviousWeekLength!) / purchasesPreviousWeekLength!) * 100).toFixed(2)}%</div>
                                </div>
                            </div>
                        </div>
                    </AnalyticsBox>
                    {/* <div>
                        <div className="w-full h-[50px]">
                            <PrimaryButton
                                text="Recupera HAs"
                                pointer={true}
                                disabled={false}
                                onClick={() => {
                                    if (!idToken) return
                                    getSurveysByType(
                                        idToken,
                                        SurveyType.HealthAssessment,
                                        (surveys) => {
                                            setSurveys(surveys)
                                        },
                                        (error) => { console.log(error) },
                                    )
                                }}
                            />
                        </div>
                        {
                            surveys &&
                            <div className="mt-[20px] w-full h-[50px]">
                                <SecondaryButton
                                    text="Salva le risposte"
                                    onClick={() => {
                                        surveys.forEach(survey => {
                                            // Map survey.data to include the created_at value as `date`
                                            const answersWithDate = survey.data.map(answer => ({
                                                ...answer,
                                                date: survey.created_at, // Assign the survey's created_at to each answer's date
                                            }));
                        
                                            saveMultipleAnswers(
                                                survey.user_id,
                                                idToken!,
                                                answersWithDate, // Pass the updated answers array
                                                (success) => { console.log(success) },
                                                (error) => { console.log(error) }
                                            );
                                        });
                                    }} />
                            </div>
                        }
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Analytics

interface AnalyticsProps {
    onClick: () => void,
    children: React.ReactNode,
}

const AnalyticsBox: React.FC<AnalyticsProps> = ({
    onClick,
    children,
}) => {
    return (
        <div className="w-full p-[20px] rounded-[15px] shadow-platform cursor-pointer hover:bg-holifya-grey" onClick={onClick}>
            {children}
        </div>
    )
}
