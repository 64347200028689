interface Props {
    text?: string,
    onClick: () => void,
}

const BackButton: React.FC<Props> = ({
    text,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className="w-fit py-[10px] flex items-center cursor-pointer"
        >
            <img src="/images/symbols/back-arrow-blue.svg" />
            <div className="font-semibold text-[14px] leading-[20px] text-holifya-blue ml-[20px]">
                {text ? text : "Indietro"}
            </div>
        </div>
    )
}

export default BackButton
