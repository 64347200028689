import { useState } from "react"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { PdfType } from "../../../utils/Enums"
import InfoDialog from "./InfoDialog"

interface Props {
  pdfType: PdfType,
  onFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
  onFileInputDelete: () => void,
  uploadedFile: File | undefined,
}

const UploadPdfSection: React.FC<Props> = ({
  pdfType,
  onFileInputChange,
  onFileInputDelete,
  uploadedFile,
}) => {
  const deleteButtonDisabled = uploadedFile === undefined

  const handleUploadPdfTitle = () => {
    switch (pdfType) {
      case PdfType.HealthPlan:
        return "Health Plan file"
      case PdfType.Diet:
        return "Dieta"
      case PdfType.ShoppingList:
        return "Lista della spesa"
      case PdfType.EatingHabits:
        return "Abitudini alimentari"
      case PdfType.TipsAndRecepes:
        return "Consigli e ricette"
      case PdfType.SeasonalFruitsAndVegetables:
        return "Frutta e verdura di stagione"
      default:
        return ""
    }
  }

  const [shouldShowInfoDialog, setShouldShowInfoDialog] = useState(false)

  // ********************************************************************************

  return (
    <div>
      <div className="space-y-[15px]">
        <div className="font-bold text-[16px] leading-[33px] flex items-center">
          {handleUploadPdfTitle()} <img className="cursor-pointer ml-[10px]" src="/images/symbols/info-logo.svg" onClick={() => setShouldShowInfoDialog(true)} />
        </div>
        <div className="w-full px-[20px] py-[30px] rounded-[5px] border border-solid border-black">
          <div className="w-full h-full flex items-center justify-between">
            <div className="font-medium text-[20px] leading-[33px]">
              {
                uploadedFile ? "File caricato" : "Carica file PDF"
              }
            </div>
            <div className="flex items-center space-x-[20px]">
              <div>
                <div className="w-[127px] h-[25px]">
                  <PrimaryButton
                    text="Carica e sostituisci"
                    pointer={true}
                    disabled={false}
                    uppercase={false}
                    fontSize={12}
                    onClick={() => (document.querySelector(`.file-input-box-${pdfType}`) as HTMLElement).click()}
                  />
                </div>
                <input
                  type="file"
                  accept="application/pdf image/jpg image/jpeg"
                  className={`file-input-box-${pdfType}`}
                  onChange={onFileInputChange}
                  hidden
                />
              </div>
              <button className="font-bold text-[12px] leading-[17px] disabled:text-holifya-grey disabled:cursor-not-allowed" disabled={true}>Scarica</button>
              <div>|</div>
              <button
                className="font-bold text-[12px] leading-[17px] cursor-pointer disabled:text-holifya-grey disabled:cursor-not-allowed"
                onClick={onFileInputDelete}
                disabled={deleteButtonDisabled}
              >
                Elimina
              </button>
            </div>
          </div>
        </div>
      </div>
      {shouldShowInfoDialog && <InfoDialog type={handleUploadPdfTitle()} onClick={() => setShouldShowInfoDialog(false)}/>}
    </div>
  )
}

export default UploadPdfSection
