import CloseButton from "../../../components/buttons/new/CloseButton"

interface Props {
    type: string,
    onClick: () => void,
}

const InfoDialog: React.FC<Props> = ({
    type,
    onClick,
}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col items-end w-[90%] space-y-[10px] md:w-[362px]">
                <CloseButton onClick={onClick} />
                <div className="self-start">
                    Le info riguardo <strong>{type}</strong> arriveranno presto.
                </div>
            </div>
        </div>
    )
}

export default InfoDialog
