import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../components/buttons/WhiteButton"
import { useUserData } from "../../../utils/context/UserDataContext"
import { handleDayOfMonth, handleHourAndMinutes, handleMissingTime, handleMonth } from "../../../utils/Functions"
import { Appointment } from "./Appointment"

interface Props {
  appointment: Appointment,
}

export const NewAppointmentWidget: React.FC<Props> = ({
  appointment,
}) => {
  const isUrgent = appointment.remainingMinutes < 1440

  // ********************************************************************************

  return (
    <div className={`w-full rounded-[15px] px-[15px] py-[20px] ${isUrgent ? "border-[2px] border-solid border-holifya-blue" : "shadow-platform"}`}>
      <div className="w-full h-full flex justify-between items-center">
        <div className="flex flex-col space-y-[20px]">
          <div className="flex items-center">
            <div className="font-bold text-[12px] leading-[20px] mr-[30px]">Videoconsulto | <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>{handleMissingTime(appointment)}</span></div>
            {
              !isUrgent && <div className="font-bold text-[12px] leading-[20px] text-holifya-red cursor-pointer" onClick={() => window.open(appointment.cancelUrl, "_blank")}> Cancella</div>
            }
          </div>
          <div className="w-full flex items-center justify-between">
            <div className="font-bold text-[40px] leading-[40px]">
              {handleDayOfMonth(appointment.date)} {handleMonth(appointment.date)} <span className="font-normal">{handleHourAndMinutes(appointment.date)}</span>
            </div>
          </div>
          <div className="font-bold text-[25px] leading-[25px]">
            {appointment.patientName}
          </div>
        </div>
        <div className="flex flex-col space-y-[10px]">
          <div className={`w-[330px] h-[50px] ${!isUrgent && "mt-[15px]"}`}>
            <PrimaryButton
              text={"Collegati"}
              pointer={true}
              disabled={false}
              onClick={() => window.open(appointment.connectUrl, "_blank")}
            />
          </div>
          {
            !isUrgent &&
            <div className="w-[330px] h-[50px]">
              <WhiteButton text="Modifica"
                onClick={() => window.open(appointment.editUrl, "_blank")} uppercase={false} />
            </div>
          }
        </div>
      </div>
    </div>
  )
}

interface SmallAppointmentProps {
  appointment: Appointment,
  nutritionist: string,
}

export const SmallAppointmentWidget: React.FC<SmallAppointmentProps> = ({
  appointment,
  nutritionist,
}) => {
  const { userData } = useUserData()
  const email = userData.email
  const isUrgent = appointment.remainingMinutes < 1440

  // ********************************************************************************

  return (
    <div
      className="w-full flex flex-col px-[10px] py-[20px] rounded-[15px] shadow-platform space-y-[20px]"
    >
      <div className="flex items-center justify-between">
        <div className="font-bold text-[12px] leading-[20px] mr-[30px]">Videoconsulto | <span className={`${isUrgent ? "text-holifya-red" : "text-holifya-blue"}`}>{handleMissingTime(appointment)}</span></div>
        {
          !isUrgent && <div className="font-bold text-[12px] leading-[20px] text-holifya-red cursor-pointer" onClick={() => window.open(appointment.cancelUrl, "_blank")}> Cancella</div>
        }
      </div>
      <div className="font-bold text-[35px] leading-[35px]">
        {handleDayOfMonth(appointment.date)} {handleMonth(appointment.date)} <span className="font-normal">{handleHourAndMinutes(appointment.date)}</span>
      </div>
      <div className="font-bold text-[20px] leading-[20px]">{appointment.patientName} || <span className="text-[15px] text-holifya-blue">{appointment.expert_email === "elvira.pistolesi@holifya.com" ? "Elvira Pistolesi" : nutritionist}</span></div>
      {
        appointment.expert_email === email &&
        <div>
          <div className="w-full h-[50px]">
            <PrimaryButton
              text="Collegati"
              pointer={true}
              disabled={false}
              onClick={() => window.open(appointment.connectUrl, "_blank")}
            />
          </div>
          {
            !isUrgent && <div className="w-full h-[50px]">
              <WhiteButton
                text="Modifica"
                onClick={() => window.open(appointment.editUrl, "_blank")}
                uppercase={false}
              />
            </div>
          }
        </div>}
    </div>
  )
}

interface OldAppointmentProps {
  appointment: Appointment,
  nutritionist: string,
}

export const OldAppointmentWidget: React.FC<OldAppointmentProps> = ({
  appointment,
  nutritionist,
}) => {
  return (
    <div className="w-full flex flex-col px-[10px] py-[20px] rounded-[15px] space-y-[20px] bg-[#F5F5F5]">
      <div className="font-bold text-[12px] leading-[20px] mr-[30px]">Videoconsulto</div>
      <div className="font-bold text-[35px] leading-[35px]">
        {handleDayOfMonth(appointment.date)} {handleMonth(appointment.date)} <span className="font-normal">{handleHourAndMinutes(appointment.date)}</span>
      </div>
      <div className="font-bold text-[20px] leading-[20px]">{appointment.patientName} || <span className="text-[15px] text-holifya-blue">{appointment.expert_email === "elvira.pistolesi@holifya.com" ? "Elvira Pistolesi" : nutritionist}</span></div>
    </div>
  )
}
