import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CloseButton from "../../../components/buttons/new/CloseButton";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { LoadingSpinner } from "../../../components/LoadingSpinner";
import { Patient } from "../../../utils/api-objects/Patient";
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants";
import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";
import { PdfType } from "../../../utils/Enums";
import { getFormattedDate } from "../../../utils/Functions";
import UploadPdfSection from "../components/UploadPdfSection";

const HealthPlanPage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isGeneralLoading, setIsGeneralLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate()

    const [healthPlanFile, setHealthPlanFile] = useState<File>()

    const onHealthPlanInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setHealthPlanFile(files![0])
    }

    const onPublishClick = async () => {
        if (!idToken || !healthPlanFile) return
        setIsLoading(true)
        const filename = `HealthPlan_${getFormattedDate()}.pdf`;
        const response = await fetch(`${BASE_URL}/api/health-plans/patient/${patientId}/upload-url?filename=${filename}&folder=health_plan`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', healthPlanFile)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)
            } else {
                console.log("File not uploaded")
            }
            setIsLoading(false)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (!patients) {
            setIsGeneralLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsGeneralLoading(false)

        if (!patientId || !idToken) return
    }, [patients, patientId])

    if (isGeneralLoading) {
        return (
            <LoadingSpinner />
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <div className="w-[89%] flex justify-between mt-[20px]">
                <div className="space-y-[30px]">
                    <div className="flex items-center space-x-[300px]">
                        <div className="">
                            <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                            <div className="font-bold text-[35px] leading-[35px]">Health Plan</div>
                        </div>
                        <div className="w-[362px] h-[50px]">
                            <PrimaryButton
                                text="Salva e pubblica"
                                onClick={onPublishClick}
                                pointer={healthPlanFile !== undefined}
                                disabled={healthPlanFile === undefined}
                                uppercase={false}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                    <UploadPdfSection
                        pdfType={PdfType.HealthPlan}
                        onFileInputChange={onHealthPlanInputChange}
                        onFileInputDelete={() => setHealthPlanFile(undefined)}
                        uploadedFile={healthPlanFile}
                    />
                </div>
                <CloseButton onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)} />
            </div>
        </div>
    )
}

export default HealthPlanPage
