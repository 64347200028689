import { Environment } from "./Enums";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "https://0k1h4aqzf5.execute-api.eu-central-1.amazonaws.com";

export const PHARMACIES_CONFIRMATION_NUMBER = "FARMA6794LH"

export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
export const PASSWORD_REGEX = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;\-_?!$€£&%@/()='"*])[^\s]{8,20}$/

export const SHOW_PASSWORD_ICON_DISABLED = "/images/symbols/show-password-disabled.svg"
export const SHOW_PASSWORD_ICON = "/images/symbols/show-password.svg"
export const HIDE_PASSWORD_ICON_DISABLED = "/images/symbols/hide-password-disabled.svg"
export const HIDE_PASSWORD_ICON = "/images/symbols/hide-password.svg"

export const multiplidi3 = [3, 6, 9, 12, 15, 18, 21, 24, 27, 30, 33, 36, 39, 42, 45, 48, 51, 54, 57, 60]

export const IMAGE_LOGO = "/images/holifyaLogos/holifya-logo-blue.svg"

export const HOME_LOGO_FOCUS = "/images/platformLogos/home-logo-focus.svg"
export const HOME_LOGO = "/images/platformLogos/home-logo.svg"
export const CUSTOMERS_LOGO_FOCUS = "/images/platformLogos/customers-logo-focus.svg"
export const CUSTOMERS_LOGO = "/images/platformLogos/customers-logo.svg"
export const APPOINTMENTS_LOGO = "/images/platformLogos/appointments-logo.svg"
export const APPOINTMENTS_LOGO_FOCUS = "/images/platformLogos/appointments-logo-focus.svg"
export const ACCOUNT_LOGO_FOCUS = "/images/platformLogos/account-logo-focus.svg"
export const ACCOUNT_LOGO = "/images/platformLogos/account-logo.svg"

export const NEW_HOME_LOGO = "/images/platformNewLogos/new-home-logo.svg"
export const NEW_HOME_LOGO_FOCUS = "/images/platformNewLogos/new-home-logo-focus.svg"
export const NEW_CUSTOMERS_LOGO = "/images/platformNewLogos/new-customers-logo.svg"
export const NEW_CUSTOMERS_LOGO_FOCUS = "/images/platformNewLogos/new-customers-logo-focus.svg"
export const NEW_APPOINTMENTS_LOGO = "/images/platformNewLogos/new-appointments-logo.svg"
export const NEW_APPOINTMENTS_LOGO_FOCUS = "/images/platformNewLogos/new-appointments-logo-focus.svg"
export const NEW_MATERIAL_LOGO = "/images/platformNewLogos/new-material-logo.svg"
export const NEW_MATERIAL_LOGO_FOCUS = "/images/platformNewLogos/new-material-logo-focus.svg"
export const NEW_ACCOUNT_LOGO = "/images/platformNewLogos/new-account-logo.svg"
export const NEW_ACCOUNT_LOGO_FOCUS = "/images/platformNewLogos/new-account-logo-focus.svg"
export const NEW_ANALYTICS_LOGO = "/images/platformNewLogos/new-analytics-logo.svg"
export const NEW_ANALYTICS_LOGO_FOCUS = "/images/platformNewLogos/new-analytics-logo-focus.svg"

export const requestOptionsGET = (idToken: string) => ({
    method: 'GET',
    headers: {
        'Authorization': idToken
    }
});

export const DEFAULT_SKILLS = [
    "Salute gastrointestinale",
    "Immunonutrizione",
    "Dieta Mediterranea",
    "Dieta Low Carb",
    "Dieta Chetogenica",
    "Nutrizione Sportiva",
    "Nutrizione Femminile",
    "Integrazione e Nutraceutici",
    "Adattogeni e Gestione dello stress",
    "Nutricosmetica",
    "Alimentazione Vegana e Vegetariana",
    "Dieta Low FODMAP",
]

export const daysOfWeek = ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'];
export const months = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || Environment.DEVELOP;

export const question_ids_dev = ["789", "831", "787", "810", "798", "801", "811"]
export const question_ids_prod = ["7", "49", "5", "28", "16", "19" , "29"]

export const initialKeyParameters = [
    { name: "obiettivo", label: "🎯 Obiettivo", value: "", isSelected: false },
    { name: "acqua", label: "💧 Acqua", value: "", isSelected: false },
    { name: "peso", label: "⚖️ Peso", value: "", isSelected: false },
    { name: "sonno", label: "🛌 Sonno", value: "", isSelected: false },
    { name: "energia", label: "⚡ Energia", value: "", isSelected: false },
    { name: "digestione", label: "🫃 Digestione", value: "", isSelected: false },
    { name: "esercizio", label: "💪 Esercizio", value: "", isSelected: false },
]
