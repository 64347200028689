import { BASE_URL } from "../Constants";
import { ExpertRole } from "../Enums";
import { ErrorWithMessageCallback, LoginErrorCallback, LoginSuccessCallback, SuccessWithMessageCallback } from "./callback-helpers";

/**
 * Logs in a user with the provided email and password.
 * 
 * @param email - The user's email address.
 * @param password - The user's password.
 * @param onSuccess - Callback function to be called if the login is successful.
 * @param onError - Callback function to be called if an error occurs during login.
 * 
 * @returns A promise that resolves to an object containing the success status and an error message if an error occurs.
 * 
 * @throws An error if the fetch operation fails.
 */
export async function loginApi(
    email: string,
    password: string,
    onSuccess: LoginSuccessCallback,
    onError: LoginErrorCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/auth/login`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password,
                    role: ExpertRole.Nutritionist,
                }
            ),
        })
        if (!response.ok) {
            const responseJson = await response.json();
            onError(responseJson)
        } else {
            const responseJson = await response.json()
            const role = (responseJson.role === "medical_user" || email === "elvira.pistolesi@holifya.com") ? "specialist" : responseJson.role
            onSuccess({
                idToken: responseJson.id_token,
                accessToken: responseJson.access_token,
                userId: responseJson.id,
                refreshToken: responseJson.refresh_token,
                expiryTime: responseJson.expiry_in,
                role,
            });
        }
    } catch (error) {
        console.error("Login failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function logoutAPI(
    email: string,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/auth/logout`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            {
                email: email,
            }
        ),
    })
    if (response.status === 302) {
        const result = await response.json();
        onSuccess(result)
    } else {
        const result = await response.json();
        onError(result)
    }
}