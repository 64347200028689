import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import { Patient } from "../api-objects/Patient";

interface PatientsState {
    patients: Patient[] | null;
}

interface PatientsContextType {
    patientsState: PatientsState;
    setPatients: (patients: Patient[]) => void;
    clearPatients: () => void;
}

const PatientsContext = createContext<PatientsContextType | undefined>(undefined);

export const PatientsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [patientsState, setPatientsState] = useState<PatientsState>(() => {
        try {
            const storedPatients = localStorage.getItem("patients");
            return { patients: storedPatients ? JSON.parse(storedPatients) : null };
        } catch (error) {
            console.error("Failed to parse patients from localStorage:", error);
            return { patients: null };
        }
    });

    useEffect(() => {
        if (patientsState.patients) {
            localStorage.setItem("patients", JSON.stringify(patientsState.patients));
        } else {
            localStorage.removeItem("patients");
        }
    }, [patientsState.patients]);

    const setPatients = (patients: Patient[] | null) => {
        setPatientsState({ patients });
    };

    const clearPatients = () => {
        setPatientsState({ patients: null });
        localStorage.removeItem("patients");
    };

    return (
        <PatientsContext.Provider value={{ patientsState, setPatients, clearPatients }}>
            {children}
        </PatientsContext.Provider>
    );
};

export const usePatients = (): PatientsContextType => {
    const context = useContext(PatientsContext);
    if (context === undefined) {
        throw new Error('usePatients must be used within an PatientsProvider');
    }
    return context;
};