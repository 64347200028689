import { MealPlan } from "../api-objects/meal-plan"
import { Patient } from "../api-objects/Patient"
import { BASE_URL, requestOptionsGET } from "../Constants"
import { ErrorWithMessageCallback, GenericSuccessCallback, GetMealPlanSuccessCallback } from "./callback-helpers"

export async function callCreateMealPlanApi(
    patient: Patient,
    idToken: string,
    nutritionRecommendations: string[],
    heroFoods: string[],
    integratori: string[],
    importantParameters: string[],
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
        body: JSON.stringify(
            {
                user_id: patient.user_id,
                data: {
                    nutrition_recommendations: nutritionRecommendations,
                    top_hero_foods: heroFoods,
                    top_integratori: integratori,
                    key_parameters: importantParameters,
                },
                user_email: patient.email,
                user_name: patient.user_name,
            }
        )
    })
    if (response.ok) {
        const responseJson = await response.json()
        onSuccess(responseJson)
    } else {
        onError("Creazione Meal Plan errata")
    }
}

export async function getMealPlanApi(
    idToken: string,
    user_id: string,
    onSuccess: GetMealPlanSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans/user/${user_id}`, requestOptionsGET(idToken))
    if (response.ok) {
        const mealPlan: MealPlan = await response.json()
        onSuccess(mealPlan)
    } else if(response.status === 404) {
        onError("Meal plan non trovato")
    } else {
        onError("Errore nel recupero del meal plan")
    }
}

export async function getMealPlanByIdApi(
    idToken: string,
    id: string,
    onSuccess: GetMealPlanSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const response = await fetch(`${BASE_URL}/api/meal-plans/id/${id}`, requestOptionsGET(idToken))
    if (response.ok) {
        const mealPlan: MealPlan = await response.json()
        onSuccess(mealPlan)
    } else if(response.status === 404) {
        onError("Meal plan non trovato")
    } else {
        onError("Errore nel recupero del meal plan")
    }
}