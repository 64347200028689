import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../components/buttons/WhiteButton";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import EmailInputElement from "../../../components/inputs/EmailInputElement";
import PasswordInputElement from "../../../components/inputs/PasswordInputElement";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../utils/Constants";
import { PasswordInputIcon, PasswordInputType } from "../../../utils/Enums";

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    passwordError: string,
}

const LoginBox: React.FC<Props> = ({
    onSubmit,
    passwordError,
}) => {
    const navigate = useNavigate()

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        email: '',
        password: '',
    });

    const [emailError, setEmailError] = useState("")
    const [pwdError, setPwdError] = useState(passwordError)

    const isEmailAcceptable = EMAIL_REGEX.test(inputs.email)
    const isPasswordAcceptable = PASSWORD_REGEX.test(inputs.password)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        // Ensure the value is in lowercase for the email field
        const formattedValue = name === "email" ? value.toLowerCase() : value;

        if (name === "email") {
            if (EMAIL_REGEX.test(e.target.value) || value === "") {
                setEmailError("")
            } else {
                setEmailError("Inserisci un indirizzo email valido")
            }
        }

        if (pwdError !== "") {
            setPwdError("")
        }

        setInputs((prev) => ({
            ...prev,
            [name]: formattedValue,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    };

    useEffect(() => {
        setPwdError(passwordError)
    }, [passwordError])

    // ********************************************************************************

    return (
        <div className="w-full flex flex-col items-center">
            <HolifyaFullLogo />
            <div className="font-normal text-[18px] leading-[24px] my-[30px] text-center">
                Inserisci le tue credenziali per accedere al mondo Holifya
            </div>
            <form
                className="w-full space-y-[20px]"
                onSubmit={handleSubmit}
            >
                <EmailInputElement
                    name="email"
                    label="Indirizzo e-mail"
                    placeholder="nome@esempio.com"
                    inputValue={inputs.email}
                    errorText={emailError}
                    onChange={handleInputChange}
                />
                <PasswordInputElement
                    name="password"
                    label="Password"
                    placeholder="Password"
                    inputValue={inputs.password}
                    disabled={!isEmailAcceptable || inputs.email === ""}
                    image={PasswordInputIcon.ShowDisabled}
                    type={PasswordInputType.Password}
                    errorText={pwdError}
                    toggleTypeEnabled={isEmailAcceptable && inputs.password !== ""}
                    onChange={handleInputChange}
                />
                <div
                    className={`w-full h-[55px] mb-[20px]`}
                >
                    <PrimaryButton
                        text="Accedi"
                        pointer={isEmailAcceptable && isPasswordAcceptable}
                        disabled={!isPasswordAcceptable || !isEmailAcceptable}
                        fontSize={12}
                    />
                </div>
            </form>
            <div className="w-full h-[55px]">
                <WhiteButton
                    text="Non riesci ad accedere?"
                    onClick={() => navigate("/forgot-password")}
                />
            </div>
        </div>
    )
}

export default LoginBox
