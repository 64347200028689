import PrimaryButton from "../../../components/buttons/PrimaryButton";
import CloseButton from "../../../components/buttons/new/CloseButton";

interface Props {
    onCloseClick: () => void,
    onConfirmClick: (updatedParameterList: Array<{ name: string; label: string; value: string; isSelected: boolean }>) => void;
    onParameterUpdate: (updatedList: Array<{ name: string; label: string; value: string; isSelected: boolean }>) => void;
    parameterList: Array<{ name: string; label: string; value: string, isSelected: boolean }>;
}

const ImportantParametersDialog: React.FC<Props> = ({
    onCloseClick,
    onConfirmClick,
    onParameterUpdate,
    parameterList,
}) => {
    const maximumNumberOfSelectedItems = 6

    const toggleParameterSelection = (paramName: string) => {
        const selectedParams = parameterList.filter(par => par.isSelected === true)
        if (selectedParams.length >= maximumNumberOfSelectedItems) {
            window.alert("Non puoi")
            return
        }
        const updatedList = parameterList.map((param) =>
            param.name === paramName ? { ...param, isSelected: !param.isSelected } : param
        );
        onParameterUpdate(updatedList);
    };

    const isConfirmDisabled = !parameterList.some((param) => param.isSelected);

    const selectedParameters = parameterList.filter(para => para.isSelected === true).map(para => para.name)

    // ********************************************************************************
    
    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-white rounded-[15px] p-[20px] flex flex-col space-y-[20px]">
                <div className="flex items-center justify-between">
                    <div className="font-bold text-[20px] leading-[40px]">Parametri importanti</div>
                    <CloseButton onClick={onCloseClick} />
                </div>
                <div
                    className={`w-[480px] bg-[#DAE8FD] border border-solid rounded-[15px] p-[20px] space-y-[10px] cursor-pointer ${parameterList[0].isSelected ? "border-holifya-blue" : "border-[#DAE8FD]"}`}
                    onClick={() => toggleParameterSelection("obiettivo")}
                >
                    <div className="font-bold text-[14px] leading-[33px]">🎯‍ Obiettivo</div>
                    <div className="font-bold text-[24px] leading-[28px]">{parameterList[0].value}</div>
                </div>
                <div className="w-full grid grid-cols-3 gap-4 justify-items-center">
                    {parameterList.slice(1).map(({ name, label, value, isSelected }) => (
                        <div
                            key={name}
                            className={`w-[136px] h-[136px] rounded-[15px] border border-solid p-[10px] space-y-[10px] cursor-pointer ${isSelected ? "border-holifya-blue bg-[#DAE8FD]" : "border-black"}`}
                            onClick={() => toggleParameterSelection(name)}
                        >
                            <div className="font-bold text-[14px] leading-[33px]">{label}</div>
                            <div className="font-bold text-[20px] leading-[24px]">{value}</div>
                        </div>
                    ))}
                </div>
                <div className="w-full h-[50px]">
                    <PrimaryButton
                        text="Conferma"
                        pointer={!isConfirmDisabled}
                        disabled={isConfirmDisabled}
                        onClick={() => onConfirmClick(parameterList)}
                    />
                </div>
            </div>
        </div>
    );
}

export default ImportantParametersDialog
