interface Props {
    text?: string,
    onClick: () => void,
}

const CloseButton: React.FC<Props> = ({
    text,
    onClick,
}) => {
    return (
        <div
            onClick={onClick}
            className="w-fit h-fit p-[10px] flex items-center cursor-pointer"
        >
            <div className="font-semibold text-[14px] leading-[20px] text-holifya-blue mr-[20px]">
                {text ? text : "Chiudi"}
            </div>
            <img src="/images/symbols/x-blue.svg" />
        </div>
    )
}

export default CloseButton
