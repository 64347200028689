import { isToday, isTomorrow, parseISO } from "date-fns"
import { daysOfWeek, months } from "../../utils/Constants"
import { useAppointments } from "../../utils/context/AppointmentsContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { NewAppointmentWidget } from "./components/NewAppointmentWidget"

const Appointments = () => {
  const { userData } = useUserData()
  const email = userData.email
  const { appointmentsState } = useAppointments()
  const allFutureActiveAppointments = appointmentsState.allFutureActiveAppointments
  const allMyFutureActiveAppointments = allFutureActiveAppointments?.filter(app => app.expert_email === email)

  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);
  const dayOfWeek = daysOfWeek[today.getDay()];
  const dayOfWeekTomorrow = daysOfWeek[tomorrow.getDay()];
  const dayOfMonth = today.getDate();
  const dayOfMonthTomorrow = tomorrow.getDate();
  const month = months[today.getMonth()];
  const monthTomorrow = months[tomorrow.getMonth()];

  // ********************************************************************************


  if (!allMyFutureActiveAppointments) {
    return (
      <div className="font-medium text-[20px] leading-[33px]">
        C'è stato un errore nel recuperare gli appuntamenti. Ti chiediamo di riprovare.
      </div>
    )
  }

  const allTodayAppointments = allMyFutureActiveAppointments.filter(app => {
    const appointmentDate = parseISO(app.date);

    return isToday(appointmentDate)
  })

  const allTomorrowAppointments = allMyFutureActiveAppointments.filter(app => {
    const appointmentDate = parseISO(app.date);

    return isTomorrow(appointmentDate)
  })

  const allIncomingAppointments = allMyFutureActiveAppointments.filter(app => {
    const appointmentDate = parseISO(app.date);

    return !isToday(appointmentDate) && !isTomorrow(appointmentDate)
  })

  // ********************************************************************************

  return (
    <div className='w-full h-full'>
      <div className="font-bold text-[35px] leading-[55px] pl-[70px] mt-[30px]">
        Appuntamenti
      </div>
      <div className="w-[89%] pl-[70px] space-y-[20px] pb-[20px] mt-[30px]">
        {
          allTodayAppointments.length > 0 &&
          <div className="space-y-[20px]">
            <div className="font-bold text-[20px] leading-[33px]">
              Oggi | <span className="font-medium">{dayOfWeek} {dayOfMonth} {month}</span>
            </div>
            {
              allTodayAppointments.map((app, index) =>
                <NewAppointmentWidget
                  key={index}
                  appointment={app}
                />
              )
            }
          </div>
        }
        {
          allTomorrowAppointments.length > 0 &&
          <div className="space-y-[20px]">
            <div className="font-bold text-[20px] leading-[33px]">
              Domani | <span className="font-medium">{dayOfWeekTomorrow} {dayOfMonthTomorrow} {monthTomorrow}</span>
            </div>
            {
              allTomorrowAppointments.map((app, index) =>
                <NewAppointmentWidget
                  key={index}
                  appointment={app}
                />
              )
            }
          </div>
        }
        {
          allIncomingAppointments.length > 0 &&
          <div className="space-y-[20px]">
            <div className="font-bold text-[20px] leading-[33px]">
              In arrivo
            </div>
            {
              allIncomingAppointments.map((app, index) =>
                <NewAppointmentWidget
                  key={index}
                  appointment={app}
                />
              )
            }
          </div>
        }
      </div>
    </div>
  )
}

export default Appointments
