import { useState } from "react";
import InfoDialog from "../../components/InfoDialog";

interface KeyParametersProps {
    onClick: () => void,
    selectedParameters: Array<{ name: string, label: string; value: string, isSelected: boolean }>;
    onSelectParamClick: (param: { name: string, label: string; value: string, isSelected: boolean }) => void,
}

const KeyParametersSection: React.FC<KeyParametersProps> = ({
    onClick,
    selectedParameters,
    onSelectParamClick,
}) => {
    const [shouldShowInfoDialog, setShouldShowInfoDialog] = useState(false)
    return (
        <div>
            <div className="space-y-[15px]">
                <div className="font-bold text-[16px] leading-[33px] flex items-center">
                    Parametri importanti
                    <img className="cursor-pointer ml-[10px]" src="/images/symbols/info-logo.svg" onClick={() => setShouldShowInfoDialog(true)} />
                </div>
                <div className="w-full flex gap-2 flex-wrap">
                    {selectedParameters.length > 0 ? (
                        selectedParameters.map((param, index) => (
                            <div
                                key={index}
                                className="w-[136px] h-[136px] border border-solid border-black bg-[#DAE8FD] rounded-[15px] p-[10px] flex flex-col space-y-[10px]"
                            >
                                <div className="w-full flex items-center justify-between">
                                    <div className="font-bold text-[14px] leading-[33px]">{param.label}</div>
                                    <div className="cursor-pointer" onClick={() => onSelectParamClick(param)}><img src="/images/symbols/delete.svg" /></div>
                                </div>
                                <div className="font-bold text-[22px] leading-[28px] break-words">{param.value}</div>
                            </div>
                        ))
                    ) : null}

                    {/* Only render "+" boxes if there are fewer than 6 selected parameters */}
                    {selectedParameters.length < 6 && (
                        Array.from({ length: 6 - selectedParameters.length }).map((_, index) => (
                            <div
                                key={index}
                                className="w-[136px] h-[136px] border border-dotted border-black rounded-[15px] flex items-center justify-center text-[50px] cursor-pointer"
                                onClick={onClick}
                            >
                                +
                            </div>
                        ))
                    )}
                </div>
            </div>
            {shouldShowInfoDialog && <InfoDialog type="Parametri importanti" onClick={() => setShouldShowInfoDialog(false)} />}
        </div>
    );
}

export default KeyParametersSection
