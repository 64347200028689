import { CalendlyAppointment } from "../api-objects/CalendlyAppointment";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GetAppointmentsSuccessCallback, handleApiResponse } from "./callback-helpers";

export async function getAppointmentsByOrganizerEmail(
    idToken: string,
    email: string,
    onSuccess: GetAppointmentsSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void
) {
    try {
        const response = await fetch(
            `${BASE_URL}/api/booking/appointments/organizer/${email}`,
            requestOptionsGET(idToken)
        );
        await handleApiResponse<CalendlyAppointment[]>(response, onSuccess, onError, handleSessionExpiry);
    } catch (error) {
        console.error("Get appointments failed:", error);
        onError("An unexpected error occurred");
    }
}
