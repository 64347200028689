import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface UserDataState {
    name: string | null;
    familyName: string | null;
    email: string | null;
    phoneNumber: string | null;
    sex: string | null;
    bio: string | null;
    professional_order: string | null;
    professional_order_number: string | null;
    professional_order_state: string | null;
    tags: string[] | null;
}

// Define the shape of the user data context
interface UserDataContextType {
    userData: UserDataState;
    updateUserData: (name: string, familyName: string, email: string, phoneNumber: string, sex: string) => void;
    updateProfessionalUserData: (bio: string, professional_order: string, professional_order_number: string, professional_order_state: string, tags: string[] | null) => void;
    clearUserData: () => void;
}

// Create the user data context with default values
const UserDataContext = createContext<UserDataContextType | undefined>(undefined);

// Create a provider component
export const UserDataProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [userData, setUserData] = useState<UserDataState>({
        name: localStorage.getItem('name'),
        familyName: localStorage.getItem('familyName'),
        email: localStorage.getItem('email'),
        phoneNumber: localStorage.getItem('phoneNumber'),
        sex: localStorage.getItem('sex'),
        bio: localStorage.getItem('bio'),
        professional_order: localStorage.getItem('professional_order'),
        professional_order_number: localStorage.getItem('professional_order_number'),
        professional_order_state: localStorage.getItem('professional_order_state'),
        tags: localStorage.getItem('tags') ? JSON.parse(localStorage.getItem('tags')!) : null,
    });

    // Effect to update localStorage when user data state changes
    useEffect(() => {
        if (userData.name) {
            localStorage.setItem('name', userData.name);
        } else {
            localStorage.removeItem('name');
        }

        if (userData.familyName) {
            localStorage.setItem('familyName', userData.familyName);
        } else {
            localStorage.removeItem('familyName');
        }

        if (userData.email) {
            localStorage.setItem('email', userData.email);
        } else {
            localStorage.removeItem('email');
        }

        if (userData.phoneNumber) {
            localStorage.setItem('phoneNumber', userData.phoneNumber);
        } else {
            localStorage.removeItem('phoneNumber');
        }

        if (userData.sex) {
            localStorage.setItem('sex', userData.sex);
        } else {
            localStorage.removeItem('sex');
        }

        if (userData.bio) localStorage.setItem('bio', userData.bio);
        else localStorage.removeItem('bio');

        if (userData.professional_order) localStorage.setItem('professional_order', userData.professional_order);
        else localStorage.removeItem('professional_order');

        if (userData.professional_order_number) localStorage.setItem('professional_order_number', userData.professional_order_number);
        else localStorage.removeItem('professional_order_number');

        if (userData.professional_order_state) localStorage.setItem('professional_order_state', userData.professional_order_state);
        else localStorage.removeItem('professional_order_state');

        if (userData.tags) localStorage.setItem('tags', JSON.stringify(userData.tags));
        else localStorage.removeItem('tags');
    }, [userData]);

    // Function to update user data
    const updateUserData = (name: string, familyName: string, email: string, phoneNumber: string, sex: string) => {
        setUserData((prev) => ({ ...prev, name, familyName, email, phoneNumber, sex }));
    };

    // Function to update professional user data
    const updateProfessionalUserData = (
        bio: string,
        professional_order: string,
        professional_order_number: string,
        professional_order_state: string,
        tags: string[] | null
    ) => {
        setUserData((prev) => ({
            ...prev,
            bio,
            professional_order,
            professional_order_number,
            professional_order_state,
            tags,
        }));
    };

    // Function to clear user data
    const clearUserData = () => {
        setUserData({ 
            name: null,
            familyName: null,
            email: null,
            phoneNumber: null,
            bio: null,
            sex: null,
            professional_order: null,
            professional_order_number: null,
            professional_order_state: null,
            tags: null,
        });
        localStorage.removeItem('name');
        localStorage.removeItem('familyName');
        localStorage.removeItem('email');
        localStorage.removeItem('phoneNumber');
        localStorage.removeItem('sex');
        localStorage.removeItem('bio');
        localStorage.removeItem('professional_order');
        localStorage.removeItem('professional_order_number');
        localStorage.removeItem('professional_order_state');
        localStorage.removeItem('tags');
    };

    // Provide context value to children
    return (
        <UserDataContext.Provider value={{ userData, updateUserData, updateProfessionalUserData, clearUserData }}>
            {children}
        </UserDataContext.Provider>
    );
};

// Custom hook to use the user data context
export const useUserData = (): UserDataContextType => {
    const context = useContext(UserDataContext);
    if (context === undefined) {
        throw new Error('useUserData must be used within a UserDataProvider');
    }
    return context;
};
