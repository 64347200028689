import { useState } from "react";
import InfoDialog from "../../components/InfoDialog";

interface HintsProps {
    hints: {
        topNutritionRecommendations: string;
        topHeroFoods: string;
        topIntegratori: string;
    },
    setHints: React.Dispatch<React.SetStateAction<{
        topNutritionRecommendations: string;
        topHeroFoods: string;
        topIntegratori: string;
    }>>;
}

const MealPlanHintsSection: React.FC<HintsProps> = ({
    hints,
    setHints,
}) => {
    const addBullets = (text: string): string => {
        return text
            .split("\n")
            .map(line => line.trim().startsWith("•") ? line : `• ${line}`)
            .join("\n");
    };
    const handleChange = (key: keyof typeof hints) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setHints(prevHints => ({
            ...prevHints,
            [key]: e.target.value,
        }));
    };

    const [shouldShowInfoDialog, setShouldShowInfoDialog] = useState(false)

    return (
        <div>
            <div className="space-y-[15px]">
                <div className="font-bold text-[16px] leading-[33px] flex items-center">
                    Consigli rapidi <img className="cursor-pointer ml-[10px]" src="/images/symbols/info-logo.svg" onClick={() => setShouldShowInfoDialog(true)} />
                </div>
                <div className="grid grid-cols-2 gap-4">
                    <div className="w-full h-full">
                        <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top nutrition recommendations</div>
                        <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                            <textarea
                                className="w-full h-full resize-none border-none focus:outline-none"
                                value={addBullets(hints.topNutritionRecommendations)}
                                onChange={handleChange("topNutritionRecommendations")}
                            />
                        </div>
                    </div>
                    <div className="w-full h-full">
                        <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top hero foods</div>
                        <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                            <textarea
                                className="w-full h-full resize-none border-none focus:outline-none"
                                value={addBullets(hints.topHeroFoods)}
                                onChange={handleChange("topHeroFoods")}
                            />
                        </div>
                    </div>
                    <div className="w-full h-full">
                        <div className="font-bold text-[14px] leading-[17px] mb-[20px]">Top integratori</div>
                        <div className="relative flex-grow w-full h-[calc(100%-37px)] rounded-[20px] border border-solid border-black p-[20px]">
                            <textarea
                                className="w-full h-full resize-none border-none focus:outline-none"
                                value={addBullets(hints.topIntegratori)}
                                onChange={handleChange("topIntegratori")}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {shouldShowInfoDialog && <InfoDialog type="Consigli rapidi" onClick={() => setShouldShowInfoDialog(false)} />}
        </div>
    );
}

export default MealPlanHintsSection
