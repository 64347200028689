import { CalendlyExpert } from "../api-objects/CalendlyExpert";
import { Patient } from "../api-objects/Patient";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ErrorWithMessageCallback, GenericSuccessCallback, GetMedicalUserSuccessCallback, GetPatientsSuccessCallback, handleApiResponse } from "./callback-helpers";

export async function getMedicalUserInfoApi(
    idToken: string,
    userId: string,
    onSuccess: GetMedicalUserSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/medical-user/${userId}`, requestOptionsGET(idToken))
        if (response.ok) {
            const user: CalendlyExpert = await response.json()
            onSuccess(user)
        } else if (response.status === 401 && handleSessionExpiry) {
            handleSessionExpiry()
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get medical user info failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getPatientsApi(
    idToken: string,
    userId: string,
    role: string,
    onSuccess: GetPatientsSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void
) {
    try {
        const response = await fetch(
            `${BASE_URL}/api/medical-user/${userId}/patients/search?role=${role}`,
            requestOptionsGET(idToken)
        );
        await handleApiResponse<Patient[]>(response, onSuccess, onError, handleSessionExpiry);
    } catch (error) {
        console.error("Get patients failed:", error);
        onError("An unexpected error occurred");
    }
}

export async function syncCalendlyApi(
    idToken: string,
    userId: string,
    onSuccess: GenericSuccessCallback,
    onError: ErrorWithMessageCallback,
) {
    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/sync-calendly/${userId}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': idToken,
        },
    })
    if (responseUserinfo.ok) {
        const success = await responseUserinfo.json()
        onSuccess(success)
    } else {
        onError("Errore nel sincronizzare Calendly")
    }
}

export async function updateMedicalUserApi(
    idToken: string,
    userId: string,
    name: string,
    family_name: string,
    phone_number: string,
    titolo: string,
    professional_order: string,
    professional_order_number: string,
    professional_order_state: string,
    tags: string[],
    bio: string,
    onSuccess: GetMedicalUserSuccessCallback,
    onError: ErrorWithMessageCallback,
  ) {
    const data = {
        name,
        family_name,
        phone_number,
        sex: titolo === "Dott." ? "M" : "F",
        professional_order,
        professional_order_number,
        professional_order_state,
        bio,
    };

    const payload = {
      data,
      tags,
    };

    const responseUserinfo = await fetch(`${BASE_URL}/api/medical-user/${userId}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': idToken,
      },
      body: JSON.stringify(payload)
    })
    if (responseUserinfo.ok) {
      const user: CalendlyExpert = await responseUserinfo.json()
      onSuccess(user)
    } else {
      onError("Errore nell'update")
    }
  }
