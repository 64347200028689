interface Props {
    name?: string,
    label: string,
    placeholder: string,
    inputValue: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    showLabel?: boolean,
    disabled?: boolean,
}

const TextInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    inputValue,
    onChange,
    showLabel = false,
    disabled = false,
}) => {
    return (
        <div className="flex flex-col w-full">
            {showLabel && (
                <label
                    htmlFor={`text-input-${label}`}
                    className="font-semibold text-[12px] uppercase"
                >
                    {label}
                </label>
            )}
            <input
                id={`text-input-${label}`}
                name={name}
                className="w-full h-[40px] border-b border-solid border-black outline-none pl-[5px] font-normal text-[16px] disabled:bg-white disabled:text-holifya-grey"
                placeholder={placeholder}
                value={inputValue}
                type="text"
                onChange={onChange}
                disabled={disabled}
            />
        </div>
    )
}

export default TextInputElement
