import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/buttons/new/BackButton"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { LoadingSpinner } from "../../../components/LoadingSpinner"
import { SortingItem } from "../../../utils/api-objects/SortingItem"
import { getAllSortingItemsApi } from "../../../utils/apis/order-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { addOneHour, getFormattedDateWithInput } from "../../../utils/Functions"

const Purchases = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)
    const [sortingItems, setSortingItems] = useState<SortingItem[]>()
    const [showDropdown, setShowDropdown] = useState(false);
    const [showDropdownType, setShowDropdownType] = useState(false);
    const [showDropdownWeek, setShowDropdownWeek] = useState(false);
    const [filteredItems, setFilteredItems] = useState<SortingItem[]>();
    const [monthOptions, setMonthOptions] = useState<{ month: number; year: number; label: string }[]>([]);
    const [weekOptions, setWeekOptions] = useState<{ start: Date; end: Date; label: string }[]>([]);
    const typeOptions = ["Dna Test", "Membership", "Altri prodotti"]

    useEffect(() => {
        if (!idToken) return
        setIsLoading(true)
        getAllSortingItemsApi(
            idToken,
            (items) => {
                setSortingItems(items)
                setFilteredItems(items)
                setIsLoading(false)
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            },
        )
    }, [idToken])

    useEffect(() => {
        // Generate month options from the current month back to October 2024
        const generateMonthOptions = () => {
            const options = [];
            const currentDate = new Date();
            const endDate = new Date(2024, 9); // October 2024 (month is 0-indexed)
            let tempDate = new Date(currentDate.getFullYear(), currentDate.getMonth());
    
            while (tempDate >= endDate) {
                const month = tempDate.getMonth();
                const year = tempDate.getFullYear();
                // Format the month and year in Italian
                const label = tempDate.toLocaleString('it-IT', { month: 'long', year: 'numeric' });
                options.push({ month, year, label });
                tempDate.setMonth(tempDate.getMonth() - 1); // Go to the previous month
            }
    
            setMonthOptions(options);
        };
    
        generateMonthOptions();
    }, []);

    useEffect(() => {
        // Generate week options from the current date
        const generateWeekOptions = () => {
            const options = [];
            const currentDate = new Date();
            let tempDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
            // Set to the start of the week (Monday)
            tempDate.setDate(tempDate.getDate() - ((tempDate.getDay() + 6) % 7));
    
            // Define correct formatting options
            const dateOptions: Intl.DateTimeFormatOptions = {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
            };
    
            for (let i = 0; i < 10; i++) { // Generate 10 weeks
                const startOfWeek = new Date(tempDate);
                const endOfWeek = new Date(tempDate);
                endOfWeek.setDate(endOfWeek.getDate() + 6); // Sunday
    
                // Format dates as dd MMM yyyy
                const startDateFormatted = startOfWeek.toLocaleDateString('it-IT', dateOptions);
                const endDateFormatted = endOfWeek.toLocaleDateString('it-IT', dateOptions);
    
                const label = `${startDateFormatted} - ${endDateFormatted}`;
                options.push({ start: startOfWeek, end: endOfWeek, label });
    
                tempDate.setDate(tempDate.getDate() - 7); // Go to the previous week
            }
    
            setWeekOptions(options);
        }; 
        generateWeekOptions();
    }, []);

    const filterItemsByWeek = (start: Date, end: Date) => {
        setIsLoading(true);
        const filtered = sortingItems?.filter(item => {
            const itemDate = addOneHour(new Date(item.created_at));
            return itemDate >= start && itemDate <= end;
        });
        setTimeout(() => {
            setFilteredItems(filtered || []);
            setIsLoading(false);
            setShowDropdownWeek(false);
        }, 500);
    };

    const filterItemsByMonth = (month: number, year: number) => {
        setIsLoading(true);
        const filtered = sortingItems?.filter(item => {
            const itemDate = addOneHour(new Date(item.created_at));
            return itemDate.getMonth() === month && itemDate.getFullYear() === year;
        });
        setTimeout(() => {
            setFilteredItems(filtered || []);
            setIsLoading(false);
            setShowDropdown(false);
        }, 500);
    };

    const filterItemsByProductType = (type: string) => {
        setIsLoading(true);
        const filtered = sortingItems?.filter(item => {
            switch (type) {
                case "Altri prodotti":
                    return item.product_type !== "dna-test" && !item.product_type.startsWith("sub");
                case "Dna Test":
                    return item.product_type === "dna-test"
                case "Membership":
                    return item.product_type.startsWith("sub")
            }
        });
        setTimeout(() => {
            setFilteredItems(filtered || []);
            setIsLoading(false);
            setShowDropdownType(false);
        }, 500);
    };

    const onResetFilterClick = () => {
        setIsLoading(true)
        setTimeout(() => {
            setFilteredItems(sortingItems || []);
            setShowDropdown(false);
            setShowDropdownType(false);
            setShowDropdownWeek(false)
            setIsLoading(false)
        }, 500)
    }

    if (isLoading || !filteredItems) {
        return (
            <LoadingSpinner />
        )
    }

    return (
        <div className="w-full h-full flex flex-col pl-[70px]">
            <div className="w-[89%] flex items-center justify-between">
                <div className="font-bold text-[35px] leading-[55px] my-[30px]">
                    Acquisti su Shopify
                </div>
                <BackButton
                    onClick={() => navigate("/platform-experts/analytics")}
                    text="Torna a Analytics"
                />
            </div>
            <div className="w-[89%] mb-[20px] flex justify-between border-b border-solid border-black pb-[20px]">
                <div>Acquisti totali: {filteredItems.length}</div>
                <div className="space-y-[10px]">
                    <div className="w-[200px] h-[50px] relative">
                        <PrimaryButton
                            text="Filtra per mese"
                            pointer={true}
                            disabled={false}
                            onClick={() => {
                                setShowDropdown(!showDropdown)
                                setShowDropdownType(false)
                                setShowDropdownWeek(false)
                            }}
                        />
                        {showDropdown && (
                            <div className="absolute top-[60px] right-0 bg-white border border-solid border-gray-300 rounded shadow-lg z-10">
                                {monthOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => filterItemsByMonth(option.month, option.year)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                                <div
                                    className="bg-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-200"
                                    onClick={onResetFilterClick}
                                >
                                    Reset Filtri
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-[200px] h-[50px] relative">
                        <PrimaryButton
                            text="Filtra per settimana"
                            pointer={true}
                            disabled={false}
                            onClick={() => {
                                setShowDropdownWeek(!showDropdownWeek)
                                setShowDropdown(false)
                                setShowDropdownType(false)
                            }}
                        />
                        {showDropdownWeek && (
                            <div className="absolute top-[60px] right-0 bg-white border border-solid border-gray-300 rounded shadow-lg z-10">
                                {weekOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => filterItemsByWeek(option.start, option.end)}
                                    >
                                        {option.label}
                                    </div>
                                ))}
                                <div
                                    className="bg-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-200"
                                    onClick={onResetFilterClick}
                                >
                                    Reset Filtri
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="w-[200px] h-[50px] relative">
                        <PrimaryButton
                            text="Filtra per tipo di prodotto"
                            pointer={true}
                            disabled={false}
                            onClick={() => {
                                setShowDropdownType(!showDropdownType)
                                setShowDropdown(false)
                                setShowDropdownWeek(false)
                            }}
                        />
                        {showDropdownType && (
                            <div className="absolute top-[60px] right-0 bg-white border border-solid border-gray-300 rounded shadow-lg z-10">
                                {typeOptions.map((option, index) => (
                                    <div
                                        key={index}
                                        className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                        onClick={() => filterItemsByProductType(option)}
                                    >
                                        {option}
                                    </div>
                                ))}
                                <div
                                    className="bg-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-200"
                                    onClick={onResetFilterClick}
                                >
                                    Reset Filtri
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="flex flex-col flex-1 w-[89%] space-y-[20px]">
                <div className="flex items-center justify-between text-[12px] px-[20px] underline">
                    <div className="w-[16%]">
                        Tipo prodotto
                    </div>
                    <div className="w-[16%]">
                        Acquirente
                    </div>
                    <div className="w-[16%]">
                        Già riscattato?
                    </div>
                    <div className="w-[16%]">
                        Riscattato da
                    </div>
                    <div className="w-[16%]">
                        Riscattabile da
                    </div>
                    <div className="w-[16%]">
                        Storico regali
                    </div>
                </div>
                <div className="flex-1 w-full space-y-[10px] overflow-y-auto scrollbar-hide pb-[20px]">
                    {
                        filteredItems ? filteredItems.map((item, index) => (
                            <SortingItemCard
                                key={index}
                                item={item}
                            />
                        )) : <div>
                            Nessun acquisto disponibile
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default Purchases

interface Props {
    item: SortingItem
}

const SortingItemCard: React.FC<Props> = ({
    item,
}) => {
    const itemRedeemed = !item.redeemability
    const redeemerAvailable = item.redeemable_by !== null
    const recipientAvailable = item.redeemed_by !== null
    return (
        <div className="w-full p-[20px] rounded-[15px] border border-solid border-black">
            <div className="w-full h-full flex items-center justify-between">
                <div className="text-[10px] w-[16%]">{item.product_type}</div>
                <div className="text-[12px] w-[16%] break-words text-secondary-action">{item.buyer}</div>
                <div className={`w-[16%] ${itemRedeemed ? "text-holifya-blue" : "text-holifya-red"}`}>{itemRedeemed ? "Sì" : "No"}</div>
                <div className={`text-[12px] w-[16%] break-words ${recipientAvailable && "text-secondary-action"}`}>{item.redeemed_by ? item.redeemed_by : "--"}</div>
                <div className={`text-[12px] w-[16%] break-words ${redeemerAvailable && "text-secondary-action"}`}>{item.redeemable_by ? item.redeemable_by : "--"}</div>
                <div className="w-[16%]">{
                    item.gifted_history.map((gift, index) =>
                        <div key={index} className="text-[10px]">
                            <div>{index + 1})</div>
                            <div className="italic ml-[10px]">
                                {getFormattedDateWithInput(gift.date)}
                            </div>
                            <div className="font-bold ml-[10px] break-words">
                                {gift.recipient}
                            </div>
                        </div>
                    )
                }</div>
            </div>
        </div>
    )
}
