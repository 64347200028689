import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/buttons/new/BackButton";
import CloseButton from "../../../components/buttons/new/CloseButton";
import LoadingSpinner from "../../../components/Loader";
import { Answer, HistoryItem } from "../../../utils/api-objects/Answer";
import { Patient } from "../../../utils/api-objects/Patient";
import { Question } from "../../../utils/api-objects/Question";
import { getAnswersByIdsAndUserId, getQuestionByQuestionCategory } from "../../../utils/apis/emr-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";
import { QuestionCategory } from "../../../utils/Enums";
import { fromQuestionCategoryToString, getFormattedDateWithInput, handleDayOfMonth, handleMonth, handleYear } from "../../../utils/Functions";

const AnswersCategory = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const patientId = searchParams.get("patient_id");
  const category = searchParams.get("category");
  const typedCategory = category as QuestionCategory
  const { patientsState } = usePatients()
  const patients = patientsState.patients

  const [patient, setPatient] = useState<Patient | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate()

  const [questions, setQuestions] = useState<Question[]>()
  const [answers, setAnswers] = useState<Answer[]>()

  useEffect(() => {
    if (!patients) {
      setIsLoading(false);
      return;
    }
    const foundPatient = patients.find((p) => p.user_id === patientId);
    if (!foundPatient) return
    setPatient(foundPatient);
    setIsLoading(false)

    if (!idToken || !patientId) {
      setIsLoading(false)
      return
    }

    getQuestionByQuestionCategory(
      typedCategory,
      (questions) => {
        setQuestions(questions)
        const ids: string[] = questions.map(question => question.id)
        getAnswersByIdsAndUserId(
          ids,
          patientId,
          idToken,
          (answers) => {
            setAnswers(answers)
            setIsLoading(false)
          },
          (error) => {
            console.log(error)
            setIsLoading(false)
          },
        )
      },
      (error) => {
        console.log(error)
      },
    )
  }, [patients, patientId, typedCategory])

  // ********************************************************************************

  if (isLoading) {
    return (
      <div className="w-full h-full pt-[20px] flex flex-col items-center ">
        <div>
          Attendi un attimo... <br />
          Stiamo caricando i dati del paziente
        </div>
      </div>
    );
  }

  if (!patient) {
    return (
      <div className="w-full h-full flex flex-col items-center">
        <div>
          Informazioni sul cliente non trovate
        </div>
        <button
          onClick={() => navigate("/platform-experts/customers")}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Torna a clienti
        </button>
      </div>
    );
  }

  return (
    <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
      <BackButton
        text={`Torna a Health Assessment`}
        onClick={() => navigate(`/platform-experts/customers/single-customer/answers?patient_id=${patientId}`)}
      />
      <div className="mt-[20px] mb-[40px]">
        <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
        <div className="font-bold text-[35px] leading-[35px]">{fromQuestionCategoryToString(typedCategory, false)}</div>
      </div>
      {
        (questions && answers) ?
          <div className="w-[89%] space-y-[20px] pb-[20px]">
            {questions.map((question, index) => {
              const answer = answers.find(answer => answer.question_id === question.id)
              if (answer === undefined) return
              return <QuestionCard key={index} question={question} answer={answer} />
            })}
          </div> :
          <div className="flex-1 w-[89%]">
            <LoadingSpinner />
          </div>
      }
    </div>
  )
}

export default AnswersCategory

interface Props {
  question: Question,
  answer: Answer,
}

const QuestionCard: React.FC<Props> = ({
  question,
  answer,
}) => {
  const [isUpdateHistoryOpen, setUpdateHistoryOpen] = useState(false);
  const [isOverrideHistoryOpen, setOverrideHistoryOpen] = useState(false);

  const last_update = answer.updated_at
  const dateText = `${handleDayOfMonth(last_update)} ${handleMonth(last_update)} ${handleYear(last_update)}`

  const someHistory = answer.overwrites_history.length > 1 || answer.updates_history.length > 1
  return (
    <div className="w-full p-[20px] rounded-[5px] border border-solid border-black">
      <div className={`w-full h-full flex ${someHistory ? "items-start" : "items-center"} justify-between`}>
        <div className="space-y-[10px]">
          <div className="font-bold text-[14px] leading-[22px]">
            {question.label}
          </div>
          {
            answer.value.map((answer, index) => (
              <div key={index} className="font-normal text-[16px] leading-[22px]">
                {answer} {question.label === "Peso" && " kg"} {question.label === "Altezza" && " cm"}
              </div>
            ))
          }
        </div>
        <div className="flex flex-col items-end space-y-[10px]">
          <div className="font-normal text-[14px] leading-[33px] whitespace-nowrap ml-[10px]">
            Ultimo aggiornamento: {dateText}
          </div>
          {
            answer.updates_history.length > 1 &&
            <button
              onClick={() => setUpdateHistoryOpen(true)}
              className="rounded-[5px] border border-solid border-black text-[12px] font-bold leading-[20px] p-[5px]"
            >
              Vedi storico aggiornamenti
            </button>}
          {
            answer.overwrites_history.length > 1 &&
            <button
              onClick={() => setOverrideHistoryOpen(true)}
              className="rounded-[5px] border border-solid border-black text-[12px] font-bold leading-[20px] p-[5px]"
            >
              Vedi storico sovrasrcizioni
            </button>
          }
        </div>
      </div>
      {
        isUpdateHistoryOpen && <HistoryPopup question={question} answer={answer} type={HistoryType.updates} onClose={() => setUpdateHistoryOpen(false)} />
      }
      {
        isOverrideHistoryOpen && <HistoryPopup question={question} answer={answer} type={HistoryType.overwrites} onClose={() => setOverrideHistoryOpen(false)} />
      }
    </div>
  )
}

enum HistoryType {
  updates = "Updates",
  overwrites = "Overwrites",
}

interface PopupProps {
  question: Question,
  answer: Answer,
  type: HistoryType,
  onClose: () => void,
}

const HistoryPopup: React.FC<PopupProps> = ({
  question,
  answer,
  type,
  onClose,
}) => {
  let history: HistoryItem[] = []

  switch (type) {
    case HistoryType.updates:
      history = answer.updates_history;
      break;
    case HistoryType.overwrites:
      history = answer.overwrites_history;
      break;
    default:
      history = [];
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-[15px] p-[20px] flex flex-col space-y-[20px]">
        <div className="flex items-center justify-between">
          <div>
            {question.question_text}
          </div>
          <CloseButton
            onClick={onClose}
          />
        </div>
        {
          history.map((sto, index) => (
            <div key={index} className="flex items-center justify-between">
              <div className="text-[10px] leading-[14px] flex flex-col">
                <div className="font-bold">
                  Data
                </div>
                <div>
                  {getFormattedDateWithInput(sto.date)}
                </div>
              </div>
              <div className="text-[10px] leading-[14px] flex flex-col items-end">
                <div className="font-bold">
                  Valore
                </div>
                {
                  sto.value.map((value, index) => (
                    <div key={index}>{value}</div>
                  ))
                }
              </div>
            </div>
          ))
        }
      </div>
    </div>
  )
}
