import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BackButton from "../../../components/buttons/new/BackButton";
import { Patient } from "../../../utils/api-objects/Patient";
import { usePatients } from "../../../utils/context/PatientsContext";
import { QuestionCategory } from "../../../utils/Enums";
import { fromQuestionCategoryToString } from "../../../utils/Functions";

const Answers = () => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate()

    useEffect(() => {
        if (!patients) {
            setIsLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);
        setIsLoading(false)
    }, [patients, patientId])

    // ********************************************************************************

    if (isLoading) {
        return (
            <div className="w-full h-full pt-[20px] flex flex-col items-center ">
                <div>
                    Attendi un attimo... <br />
                    Stiamo caricando i dati del paziente
                </div>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    const onFolderClick = (category: QuestionCategory) => {
        navigate(`category?patient_id=${patient.user_id}&category=${category}`)
    }

    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <BackButton
                text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
            />
            <div className="mt-[20px] mb-[40px]">
                <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                <div className="font-bold text-[35px] leading-[35px]">Health Assessment</div>
            </div>
            <div className="w-[89%] grid grid-cols-2 gap-4">
                <HAFolder
                    category={QuestionCategory.PersonalGoals}
                    onClick={() => onFolderClick(QuestionCategory.PersonalGoals)}
                />
                <HAFolder
                    category={QuestionCategory.FamilyHistoryDiseases}
                    onClick={() => onFolderClick(QuestionCategory.FamilyHistoryDiseases)}
                />
                <HAFolder
                    category={QuestionCategory.LifestyleSports}
                    onClick={() => onFolderClick(QuestionCategory.LifestyleSports)}
                />
                <HAFolder
                    category={QuestionCategory.DietaryHabits}
                    onClick={() => onFolderClick(QuestionCategory.DietaryHabits)}
                />
                <HAFolder
                    category={QuestionCategory.Notes}
                    onClick={() => onFolderClick(QuestionCategory.Notes)}
                />
            </div>
        </div>
    )
}

export default Answers

interface HAFolderProps {
    category: QuestionCategory,
    onClick: () => void,
}

const HAFolder: React.FC<HAFolderProps> = ({
    category,
    onClick,
}) => {
    return (
        <div className="w-[calc(100%-4px)] h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer" onClick={onClick}>
            <div className="w-[90%]">
                <div className="font-bold text-[20px] leading-[33px]">{fromQuestionCategoryToString(category)}</div>
            </div>
        </div>
    )
}
