import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import "./index.css";
import { AppointmentsProvider } from './utils/context/AppointmentsContext';
import { AuthProvider } from './utils/context/AuthContext';
import { PatientsProvider } from './utils/context/PatientsContext';
import { UserDataProvider } from './utils/context/UserDataContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppointmentsProvider>
        <PatientsProvider>
          <UserDataProvider>
            <AuthProvider>
              <App />
            </AuthProvider>
          </UserDataProvider>
        </PatientsProvider>
      </AppointmentsProvider>
    </BrowserRouter>
  </React.StrictMode>
);
