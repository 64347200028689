import { PdfType } from "../../utils/Enums"

const Material = () => {
    const displayRealPage = false

    // ********************************************************************************

    return (
        <div className="w-full h-full pl-[70px]">
            <div className="font-bold text-[35px] leading-[55px] my-[30px]">
                Materiale
            </div>
            {
                displayRealPage ?
                    <div className="w-[891px] mt-[20px]">
                        <div className="font-bold text-[16px] leading-[33px]">
                            Template
                        </div>
                        <TemplateCard
                            type={PdfType.HealthPlan}
                        />
                        <TemplateCard
                            type={PdfType.Diet}
                        />
                        <TemplateCard
                            type={PdfType.ShoppingList}
                        />

                        <TemplateCard
                            type={PdfType.TipsAndRecepes}
                        />
                        <TemplateCard
                            type={PdfType.EatingHabits}
                        />
                        <TemplateCard
                            type={PdfType.SeasonalFruitsAndVegetables}
                        />
                    </div> :
                    <div className="font-normal text-[16px] leading-[33px]">
                        Questa sezione sarà presto disponibile.
                    </div>
            }
        </div>
    )
}

export default Material

interface TemplateProps {
    type: PdfType,
}

const TemplateCard: React.FC<TemplateProps> = ({
    type,
}) => {
    const handleTemplateName = () => {
        switch (type) {
            case PdfType.HealthPlan:
                return "Health Plan file"
            case PdfType.Diet:
                return "Dieta"
            case PdfType.ShoppingList:
                return "Lista della spesa"
            case PdfType.TipsAndRecepes:
                return "Tips e ricette"
            case PdfType.EatingHabits:
                return "Eating habits"
            case PdfType.SeasonalFruitsAndVegetables:
                return "Frutta e verdura di stagione"
        }
    }
    return (
        <div className="w-full h-[90px] rounded-[5px] border border-solid border-black my-[20px] p-[20px]">
            <div className="w-full h-full flex items-center justify-between">
                <div className="font-normal text-[20px] leading-[33px]">
                    Template {handleTemplateName()}
                </div>
                <div className="flex">
                    <div className="rounded-[5px] px-[20px] py-[10px] bg-[#E8832A] font-bold text-[12px] leading-[17px] text-white cursor-pointer">
                        Scarica .ppt
                    </div>
                    <div className="rounded-[5px] px-[20px] py-[10px] bg-holifya-blue font-bold text-[12px] leading-[17px] text-white cursor-pointer mx-[10px]">
                        Scarica .docx
                    </div>
                    <div className="rounded-[5px] px-[20px] py-[10px] bg-[#F24646] font-bold text-[12px] leading-[17px] text-white cursor-pointer">
                        Scarica .pdf
                    </div>
                </div>
            </div>
        </div>
    )
}
