import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import SecondaryButton from "../../../components/buttons/SecondaryButton";
import BackButton from "../../../components/buttons/new/BackButton";
import { BASE_URL, requestOptionsGET } from "../../../utils/Constants";
import { BucketFolder, PdfType } from "../../../utils/Enums";
import { Patient } from "../../../utils/api-objects/Patient";
import { getAllExamsByType } from "../../../utils/apis/emr-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { usePatients } from "../../../utils/context/PatientsContext";
import PdfCard from "../components/PdfCard";
import { PdfExam } from "../components/PdfExam";

const SharedFromEquipe = () => {
    const { auth } = useAuth()
    const role = auth.role
    const idToken = auth.idToken
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const patientId = searchParams.get("patient_id");
    const { patientsState } = usePatients()
    const patients = patientsState.patients

    const [patient, setPatient] = useState<Patient | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const [uploadedFile, setUploadedFile] = useState<File>()

    const navigate = useNavigate()

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setUploadedFile(files![0])
    }

    const [triggerRefresh, setTriggerRefresh] = useState(false)

    const [files, setFiles] = useState<PdfExam[]>()

    useEffect(() => {
        if (!patients) {
            setIsLoading(false);
            return;
        }
        const foundPatient = patients.find((p) => p.user_id === patientId);
        if (!foundPatient) return
        setPatient(foundPatient);

        if (!patientId || !idToken) return

        setIsLoading(false)

    }, [patients, patientId])

    useEffect(() => {
        if (!patientId || !idToken) return
        getAllExamsByType(
            patientId,
            idToken,
            BucketFolder.UploadedFromExpert,
            (exams) => { setFiles(exams) },
            (error) => { console.log(error) },
        )
    }, [triggerRefresh])

    if (isLoading) {
        return (
            <div className="w-full h-full pt-[20px] flex flex-col items-center ">
                <div>
                    Attendi un attimo... <br />
                    Stiamo caricando i dati del paziente
                </div>
            </div>
        );
    }

    if (!patient) {
        return (
            <div className="w-full h-full flex flex-col items-center">
                <div>
                    Informazioni sul cliente non trovate
                </div>
                <button
                    onClick={() => navigate("/platform-experts/customers")}
                    className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
                >
                    Torna a clienti
                </button>
            </div>
        );
    }

    const onUploadClick = () => {
        (document.querySelector(`.file-input-box-${PdfType.Uploads}`) as HTMLElement).click()
    };

    const onPublishClick = async () => {
        if (!idToken || !uploadedFile) return
        setIsLoading(true)
        const filename = uploadedFile.name;
        const response = await fetch(`${BASE_URL}/api/health-plans/user/${patientId}/upload-url?filename=${filename}&folder=from_expert`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            console.log(urlResponse)
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setTriggerRefresh(true)
                setUploadedFile(undefined)
            } else {
                console.log("File not uploaded")
            }
            setIsLoading(false)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            setIsLoading(false)
        }
    }
    return (
        <div className="w-full h-full flex flex-col pt-[20px] pl-[70px]">
            <BackButton
                text={`Torna a ${patient.user_name} ${patient.user_family_name}`}
                onClick={() => navigate(`/platform-experts/customers/single-customer?patient_id=${patientId}`)}
            />
            <div className="w-[89%] my-[20px] flex items-center justify-between">
                <div>
                    <div className="font-bold text-[20px] leading-[33px]">{patient.user_name} {patient.user_family_name}</div>
                    <div className="font-bold text-[35px] leading-[35px]">Caricati dall'Equipe Medica</div>
                </div>
                <div className="space-y-[20px]">
                    <div className="w-[362px] h-[50px]">
                        <PrimaryButton
                            text="Carica"
                            pointer={true}
                            disabled={false}
                            onClick={onUploadClick}
                        />
                    </div>
                    {
                        uploadedFile &&
                        <div className="w-full h-[50px] mb-[20px] md:w-[362px]">
                            <SecondaryButton
                                text="Carica"
                                pointer={true}
                                disabled={false}
                                onClick={onPublishClick}
                            />
                        </div>
                    }
                </div>
                <input
                    type="file"
                    accept="application/pdf image/jpg image/jpeg"
                    className={`file-input-box-${PdfType.Uploads}`}
                    onChange={onFileInputChange}
                    hidden
                />
            </div>
            <div className="flex flex-wrap gap-4">
                {
                    files && files.length > 0 && files.map((exam, index) => (
                        <PdfCard key={index} exam={exam} pdfType={PdfType.Uploads} />
                    ))
                }
            </div>
        </div>
    )
}

export default SharedFromEquipe
